import "./App.css";
import PageRouter from "./Modules/PageRouter/PageRouter";

function App() {
  return (
    <div>
      <PageRouter />
    </div>
  );
}

export default App;
