import React from "react";
import homeComming from "../../images/coming soon Banner Landscape.svg";

function ComingSoonError() {
  return (
    <main>
      <div className="homeCommingParent">
        <img src={homeComming} alt="" />
      </div>
    </main>
  );
}

export default ComingSoonError;
