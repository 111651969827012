import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../HomePage/Home";
import AboutPage from "../About/AboutPage";
import ContacusPage from "../ContactUs/ContacusPage";
import SqlServerTips from "../DropdownPages/SqlServerTips";
import OurProducts from "../DropdownPages/OurProducts";
import FourZeroFourPage from "../ErrorPages/FourZeroFourPage";
import NavBar from "../Navigation/NavBar";
import Footer from "../Navigation/Footer";
import ComingSoonPage from "../ComingSoon/ComingSoonPage";
import ComingSoonError from "../ComingSoon/ComingSoonError";

function PageRouter() {
  return (
    <div>
      <Router>
        {/* <NavBar /> */}
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<ComingSoonPage />} />
          {/* <Route path="/aboutus" element={<AboutPage />} />
          <Route path="/contactus" element={<ContacusPage />} />
          <Route path="/SqlServerTips" element={<SqlServerTips />} />
          <Route path="/OurProducts" element={<OurProducts />} /> */}
          {/* <Route path="*" element={<FourZeroFourPage />} /> */}
          <Route path="*" element={<ComingSoonError />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default PageRouter;
